import React from "react";
import { DateInput, Filter, NumberInput } from "react-admin";

const ModalListFilters: React.FC = () => {
  return (
    <Filter>
      <NumberInput source="validityDuration:gte" label={"Min duration"} alwaysOn />
      <DateInput source="activatedAt:lte" label={"Max validity"} alwaysOn />
      <DateInput source="activatedAt:gte" label={"Min validity"} alwaysOn />
      <DateInput source="createdAt:lte" label={"Max created"} alwaysOn />
      <DateInput source="createdAt:gte" label={"Min created"} alwaysOn />
    </Filter>
  );
}

export default ModalListFilters;