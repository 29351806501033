import {
  Edit,
  EditProps,
  TextInput,
  SelectInput,
} from "react-admin";
import React from "react";
import { Form } from "../../Components";
import { mapEnum } from "../../Helpers";
import { VoucherStatus } from "../../Types/Enums";

const VoucherEdit: React.FC<EditProps> = (props: EditProps) => {
  return (
    <Edit {...props}>
      <Form>
        <TextInput disabled source={"id"} />
        <TextInput source={"code"} />
        <SelectInput
          source="status"
          choices={mapEnum(VoucherStatus)}
        />
      </Form>
    </Edit>
  );
};

export default VoucherEdit;