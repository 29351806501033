import React from "react";
import { DateInput, Filter } from "react-admin";

const ModalListFilters: React.FC = () => {
  return (
    <Filter>
      <DateInput source="updatedAt:lte" label={"Max updated"} alwaysOn />
      <DateInput source="updatedAt:gte" label={"Min updated"} alwaysOn />
      <DateInput source="createdAt:lte" label={"Max created"} alwaysOn />
      <DateInput source="createdAt:gte" label={"Min created"} alwaysOn />
    </Filter>
  );
}

export default ModalListFilters;