type Filter = { where: { [key in string]: string | number } };

const getSequelizeWhere = (
  filter: { [key in string]: string | number },
  separator = ":"
): Filter => {
  return Object.entries(filter).reduce(
    (acc, [key, value]) => {
      if (!key.includes(separator)) {
        acc.where[key] = value;
        return acc;
      }

      const [source, operator] = key.split(separator);

      if (!(source in acc.where)) acc.where[source] = {};

      acc.where[source][`$${operator}`] = value;

      return acc;
    },
    {
      where: {},
    }
  );
};

export { getSequelizeWhere };
