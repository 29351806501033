import React from "react";
import { BulkActionProps, BulkDeleteButton } from "react-admin";
import { BulkActionWrapper } from "../../../Components";
import BulkUpdateStatus from "./Bulk/BulkUpdateStatus";
import { Flag } from "@material-ui/icons";

const ListBulkActions: React.FC<BulkActionProps> = (props: BulkActionProps) => (
  <>
    <BulkDeleteButton {...props} label={"Delete"} />
    <BulkActionWrapper {...props} resource={"vouchers"} component={BulkUpdateStatus} label={"Update status"} icon={<Flag />} />
  </>
);

export default ListBulkActions;