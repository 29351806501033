import React from "react";
import { SaveButton, SimpleForm, SimpleFormProps, Toolbar } from "react-admin";

interface FormProps extends SimpleFormProps {
  whitelist?: string[];
  omit?: string[];
}

const Form: React.FC<FormProps> = (props: FormProps) => {
  const { whitelist = [], omit = [] } = props;

  React.Children.forEach(props.children, (children) => {
    if (!React.isValidElement(children)) return;

    const { source } = children.props;
    if (source && !omit.includes(source)) whitelist.push(source);
  });

  const CustomToolBar = (props) => (
    <Toolbar {...props}>
      <SaveButton
        transform={(data) => {
          const filtered = { ...data };

          Object.keys(filtered).forEach((key) => {
            if (!whitelist.includes(key)) {
              delete filtered[key];
            }
          });
          return filtered;
        }}
      />
    </Toolbar>
  );

  return (
    <SimpleForm {...props} toolbar={<CustomToolBar />}>
      {props.children}
    </SimpleForm>
  );
};

export default Form;
