import {
  Edit,
  EditProps,
  TextInput,
} from "react-admin";
import { Form } from "../../Components";
import React from "react";

const VoucherGroupEdit: React.FC<EditProps> = (props: EditProps) => {
  return (
    <Edit {...props}>
      <Form>
        <TextInput disabled source={"id"} />
        <TextInput source={"name"} />
      </Form>
    </Edit>
  );
};

export default VoucherGroupEdit;