import React from "react";
import { CreateButton, ExportButton, TopToolbar } from "react-admin";

const ListActions: React.FC = () => (
  <TopToolbar>
    <CreateButton />
    <ExportButton />
  </TopToolbar>
);

export default ListActions;