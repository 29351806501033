import React from "react";
import { StylizedField, StylizedFieldProps } from ".";

const BadgeField: React.FC<StylizedFieldProps> = (props: StylizedFieldProps) => {
    return (
        <StylizedField {...props} setStyle={(value) => {
            return {
                ...props.setStyle(value),
                borderRadius: "5px",
                textTransform: "lowercase",
                fontWeight: "bold",
                color: "white",
                padding: "4px 4px 5px 4px"
            };
        }} />
    );
};

export default BadgeField;