import React from "react";
import { DateInput, Filter, NumberInput, TextInput } from "react-admin";

const ModalListFilters: React.FC = () => {
  return (
    <Filter>
      <NumberInput source="totalAmount:lte" label={"Max total amount"} alwaysOn />
      <NumberInput source="totalAmount:gte" label={"Min total amount"} alwaysOn />
      <NumberInput source="amountToPay:lte" label={"Max amount to pay"} alwaysOn />
      <NumberInput source="amountToPay:gte" label={"Min amount to pay"} alwaysOn />
      <DateInput source="updatedAt:lte" label={"Max updated"} alwaysOn />
      <DateInput source="updatedAt:gte" label={"Min updated"} alwaysOn />
      <DateInput source="createdAt:lte" label={"Max created"} alwaysOn />
      <DateInput source="createdAt:gte" label={"Min created"} alwaysOn />
      <TextInput source="currency:substring" label={"Currency"} alwaysOn />
    </Filter>
  );
};

export default ModalListFilters;