import React from "react";
import { Box, IconButton } from "@material-ui/core";
import { FirstPage, KeyboardArrowLeft, KeyboardArrowRight, LastPage } from "@material-ui/icons";

interface ListPaginationActionsProps {
  count: number;
  page: number;
  rowsPerPage: number;
  onPageChange: (event: React.MouseEvent<HTMLButtonElement>, newPage: number) => void;
}

const ListPaginationActions = (props: ListPaginationActionsProps): JSX.Element => {

  const { count, page, rowsPerPage, onPageChange } = props;

  const onFirstPage = (event: React.MouseEvent<HTMLButtonElement>) => {
    onPageChange(event, 0);
  };

  const onPreviousPage = (event: React.MouseEvent<HTMLButtonElement>) => {
    onPageChange(event, page - 1);
  };

  const onNextPage = (event: React.MouseEvent<HTMLButtonElement>) => {
    onPageChange(event, page + 1);
  };

  const onLastPage = (event: React.MouseEvent<HTMLButtonElement>) => {
    onPageChange(event, Math.max(1, Math.ceil(count / rowsPerPage) - 1));
  };

  return (
    <Box display={"flex"} marginX={"10px"}>

      <IconButton
        onClick={onFirstPage}
        disabled={page === 0}
        aria-label="first page"
        color={"primary"}
      >
        <FirstPage />
      </IconButton>
      <IconButton onClick={onPreviousPage} disabled={page === 0} aria-label="previous page">
        <KeyboardArrowLeft />
      </IconButton>

      <IconButton
        onClick={onNextPage}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="next page"
      >
        <KeyboardArrowRight />
      </IconButton>
      <IconButton
        onClick={onLastPage}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="last page"
        color={"primary"}
      >
        <LastPage />
      </IconButton>

    </Box>
  );
};

export default ListPaginationActions;