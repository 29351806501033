import React, { ReactElement, useState } from "react";
import { useLogin, useNotify, Notification } from "react-admin";
import {
  Button,
  TextField,
  FormControl,
  Card,
  CardContent,
  Box,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import Logo from "../../assets/moment.png";

const useStyles = makeStyles({
  form: {
    display: "flex",
    padding: "10px",
  },
  field: {
    marginBottom: "30px",
  },
  button: {
    margin: "5px",
  },
  logo: {
    maxWidth: 250,
    margin: "20px 0",
  },
});

const Login = (): ReactElement => {
  const classes = useStyles();

  const [serverUrl, setServerUrl] = useState<string>("");
  const [publicKey, setPublicKey] = useState<string>("");
  const [contentApiUrl, setContentApiUrl] = useState<string>("");

  const login = useLogin();
  const notify = useNotify();

  const submit = async () => {
    if (!serverUrl || !publicKey) return;

    try {
      await login({
        serverUrl: serverUrl,
        publicKey: publicKey,
        contentApiUrl: contentApiUrl,
      });
      notify(`You are now logged-in`, "success");
      if (!contentApiUrl)
        notify(
          `You are using the default content api '${contentApiUrl.replace(
            "checkout",
            "api"
          )}'`,
          "info"
        );
    } catch (ex) {
      notify(`${ex}`, "error");
    }
  };

  return (
    <Box
      display={"flex"}
      height={"100vh"}
      justifyContent={"center"}
      alignItems={"center"}
    >
      <Card>
        <CardContent>
          <img src={Logo} className={classes.logo} alt={"Moment Logo"} />
          <FormControl className={classes.form}>
            <TextField
              className={classes.field}
              required
              name="Server Url"
              value={serverUrl}
              label="Server Url"
              variant="outlined"
              onChange={(e) => setServerUrl(e.target.value)}
              onBlur={() => {
                if (serverUrl.includes("-checkout")) {
                  const api = serverUrl.replace("-checkout", "-api");
                  setContentApiUrl(api);
                }
              }}
            />
            <TextField
              className={classes.field}
              required
              name="Public Key"
              type="Public Key"
              value={publicKey}
              label="Public Key"
              variant="outlined"
              onChange={(e) => setPublicKey(e.target.value)}
            />
            <TextField
              className={classes.field}
              required
              name="Content Api"
              type="Content Api"
              value={contentApiUrl}
              label="Content Api"
              variant="outlined"
              onChange={(e) => setContentApiUrl(e.target.value)}
            />
            <Button onClick={submit} variant={"contained"} color={"primary"}>
              Login
            </Button>
          </FormControl>
        </CardContent>
      </Card>
      <Notification />
    </Box>
  );
};
export default Login;