import {
  ListProps,
  Datagrid,
  TextField,
  DateField, ShowButton
} from "react-admin";
import React from "react";

import { List } from "../../Components";
import ListFunctionalities from "./ListFunctionalities";

const PaymentCredentialList: React.FC<ListProps> = (props: ListProps) => (
  <List {...props} {...ListFunctionalities}>
    <Datagrid rowClick={"show"}>
      <TextField source="provider" />
      <DateField source="createdAt" />
      <DateField source="updatedAt" />
      <ShowButton />
    </Datagrid>
  </List>
);

export default PaymentCredentialList;
