import React from "react";
import { Filter, TextInput } from "react-admin";

const ListFilters: React.FC = () => {
  return (
    <Filter>
      <TextInput source="provider:substring" label={"Provider"} alwaysOn />
    </Filter>
  );
}

export default ListFilters;