import React from "react";
import { ReactElement } from "react";
import { Resource } from "react-admin";
import { ResourceConfiguration, ResourceChildConfiguration } from ".";

const shouldBeMapped = (
  configuration: ResourceConfiguration | ResourceChildConfiguration
): boolean => !!configuration.enabled;

const Resources = (configuration: ResourceConfiguration[]): ReactElement[] => {
  const reduced: ReactElement[] = [];

  return Object.values(configuration)
    .filter(shouldBeMapped)
    .reduce((accumulator, resource) => {
      accumulator.push(
        <Resource
          key={resource.name}
          {...resource}
          options={{
            parent: resource.children === undefined ? undefined : resource.name,
            enabled: resource.enabled,
          }}
        />
      );

      resource.children?.filter(shouldBeMapped).forEach((child, index) => {
        accumulator.push(
          <Resource
            key={index}
            {...child}
            options={{ parent: resource.name, enabled: child.enabled }}
          />
        );
      });

      return accumulator;
    }, reduced);
};

export default Resources;
