import React, { ReactElement, useCallback } from "react";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
} from "@material-ui/core";
import { Button } from "react-admin";
import { Close, Search } from "@material-ui/icons";

interface ListModalFilterProps {
  filters?: ReactElement | ReactElement[];
}

const ListModalFilter: React.FC<ListModalFilterProps> = (
  props: ListModalFilterProps
) => {
  const [open, setOpen] = React.useState(false);

  const handleOpen = useCallback(() => {
    setOpen(true);
  }, []);
  const handleClose = useCallback(() => {
    setOpen(false);
  }, []);

  return (
    <>
      <Button
        style={{ marginLeft: "25px" }}
        onClick={handleOpen}
        label={"Filter"}
        variant={"contained"}
      >
        <Search />
      </Button>
      <Dialog
        disableBackdropClick
        disableEscapeKeyDown
        open={open}
        onClose={handleClose}
      >
        <DialogTitle>Filters</DialogTitle>
        <DialogContent>
          <FormControl>{props.filters}</FormControl>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} label={"Close"}>
            <Close />
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default ListModalFilter;
