import React from "react";
import { Filter, SelectInput, TextInput } from "react-admin";
import { mapEnum } from "../../../Helpers";
import { PaymentStatus } from "../../../Types/Enums";

const ListFilters: React.FC = () => {
  return (
    <Filter>
      <TextInput source="buyerName:substring" label={"Name"} alwaysOn />
      <TextInput source="buyerEmail:substring" label={"Email"} alwaysOn />
      <SelectInput source="status:substring" label={"Status"} choices={mapEnum(PaymentStatus)} alwaysOn />
    </Filter>
  );
}

export default ListFilters;