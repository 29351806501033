import React from "react";

import ListActions from "./ListActions";
import ModalListFilters from "./ModalListFilters";
import ListFilters from "./ListFilters";

export default {
  actions: <ListActions />,
  modalFilters: <ModalListFilters />,
  filters: <ListFilters />
}