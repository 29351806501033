import React, { PropsWithChildren, ReactElement } from "react";
import {
  BulkActionsToolbar,
  ListBase,
  ListProps as MuiListProps,
  Title,
  TopToolbar
} from "react-admin";
import { Box, Card, CardContent } from "@material-ui/core";

import { ListModalFilter, ListPagination } from ".";

interface ListProps extends MuiListProps {
  modalFilters?: ReactElement | ReactElement[];
}

const List: React.FC<ListProps> = (
  props: PropsWithChildren<ListProps>
) => {
  return (
    <ListBase {...props}>
      <Title title={props.title} />
      <Box display={"flex"} justifyContent={"flex-end"} alignItems={"center"}>
        {props.filters}
        <span style={{ flexGrow: 1 }}></span>
        {props.actions}
        {props.modalFilters && (
          <TopToolbar>
            <ListModalFilter filters={props.modalFilters} />
          </TopToolbar>
        )}
      </Box>
      <Box
        display={"flex"}
        marginBottom={"10px"}
        flexDirection={"column"}
        flexGrow={1}
      >
        <Card>
          <BulkActionsToolbar>{props.bulkActionButtons}</BulkActionsToolbar>
          <CardContent>
            {/* eslint-disable-next-line @typescript-eslint/no-explicit-any*/}
            {React.cloneElement(props.children as any, {
              hasBulkActions: props.bulkActionButtons !== false,
            })}
          </CardContent>
        </Card>
        <ListPagination />
      </Box>
    </ListBase>
  );
};

export default List;