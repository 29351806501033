import {
  ListProps,
  Datagrid,
  TextField,
  DateField,
  NumberField,
  ReferenceField,
  ShowButton,
} from "react-admin";
import React from "react";

import { useTheme } from "@material-ui/core";

import ListFunctionalities from "./ListFunctionalities";
import { PaymentSessionStatus } from "../../Types/Enums";
import { BadgeField, List } from "../../Components";

const PaymentSessionList: React.FC<ListProps> = (props: ListProps) => {
  const theme = useTheme();

  return (
    <List {...props} {...ListFunctionalities}>
      <Datagrid rowClick={"show"}>
        <ReferenceField reference={"voucher-groups"} source={"deviceId"}>
          <TextField style={{ fontWeight: "bold" }} source={"name"} />
        </ReferenceField>
        <BadgeField
          source="status"
          setStyle={(value) => {
            let color;
            switch (value) {
              case PaymentSessionStatus.PENDING:
                color = theme.palette.warning.main;
                break;
              case PaymentSessionStatus.STARTED:
                color = theme.palette.secondary.main;
                break;
              default:
                color = theme.palette.secondary.main;
            }
            return { backgroundColor: color };
          }}
        />
        <NumberField source="totalAmount" />
        <NumberField source="amountToPay" />
        <NumberField source="currency" />
        <DateField source="createdAt" />
        <DateField source="updatedAt" />
        <ShowButton />
      </Datagrid>
    </List>
  );
};

export default PaymentSessionList;
