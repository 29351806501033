import {
  useRecordContext,
  useDataProvider,
  ReferenceFieldProps,
  RecordContext,
  Record,
} from "react-admin";
import React, {
  PropsWithChildren,
  useCallback,
  useEffect,
  useState,
} from "react";

type ReferenceOneFieldProps = Omit<ReferenceFieldProps, "source">;

const ReferenceOneField: React.FC<ReferenceOneFieldProps> = (
  props: PropsWithChildren<ReferenceOneFieldProps>
) => {
  const [state, setstate] = useState<Record>();
  const { id } = useRecordContext(props);
  const provider = useDataProvider();

  const fetch = useCallback(() => {
    provider.getOne(props.reference, { id }).then((response) => {
      setstate(response.data);
    });
  }, [provider, props.reference, id]);

  useEffect(() => {
    fetch();
  }, [fetch, provider]);

  return state ? (
    <RecordContext.Provider value={state}>
      {props.children}
    </RecordContext.Provider>
  ) : null;
};

export default ReferenceOneField;
