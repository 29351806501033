import {
  DateField,
  NumberField, ReferenceField,
  Show,
  ShowProps,
  SimpleShowLayout, TextField,
} from "react-admin";
import React from "react";

const PaymentShow: React.FC<ShowProps> = (props: ShowProps) => {
  return (
    <Show {...props}>
      <SimpleShowLayout>
        <TextField source="id" />
        <ReferenceField reference={"payment-sessions"} source={"paymentSessionId"}>
          <TextField source={"id"} />
        </ReferenceField>
        <TextField source="reference" />
        <TextField source="status" />
        <TextField source="buyerMail" />
        <TextField source="buyerName" />
        <TextField source="method" />
        <NumberField source="amount" />
        <TextField source="currency" />
        <TextField source="message" />
        <DateField source="createdAt" />
        <DateField source="updatedAt" />
      </SimpleShowLayout>
    </Show>
  );
};

export default PaymentShow;