import React from 'react';

import { LayoutProps, Notification } from 'react-admin';

import { Menu, Header } from ".";

import { makeStyles, ThemeProvider } from "@material-ui/styles";
import theme from "../../Theme";

const useStyles = makeStyles(({
  root: {
    backgroundColor: "#fdfdfd",
    display: 'flex',
    flexDirection: 'column',
    zIndex: 1,
    minHeight: '100vh',
    position: 'relative',
  },
  appFrame: {
    display: 'flex',
    flexDirection: 'column',
    overflowX: 'auto',
  },
  contentWithSidebar: {
    display: 'flex',
    marginTop: '4em',
    margin: "10px 0"
  },
  content: {
    flexGrow: 1,
    display: 'flex',
    flexDirection: 'column',
    padding: "0 35px",
  },
}));

const Layout: React.FC<LayoutProps> = (props: LayoutProps) => {
  const classes = useStyles();
  return (
    <ThemeProvider theme={theme}>
      <div className={classes.root}>
        <div className={classes.appFrame}>
          <Header open={true} logout={props.logout} title={props.title} />
          <main className={classes.contentWithSidebar}>
            <Menu logout={props.logout} hasDashboard={!!props.dashboard} />
            <div className={classes.content}>
              {props.children}
            </div>
          </main>
          <Notification />
        </div>
      </div>
    </ThemeProvider>
  );
};

export default Layout;