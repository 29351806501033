import {
  ListProps,
  EditButton,
  DeleteButton,
  DateField,
  Datagrid,
  TextField,
  ShowButton,
  NumberField,
  ReferenceField,
} from "react-admin";
import React from "react";

import ListFunctionalities from "./ListFunctionalities";
import { VoucherStatus } from "../../Types/Enums";
import { useTheme } from "@material-ui/core";
import { BadgeField, List } from "../../Components";

const VoucherList: React.FC<ListProps> = (props: ListProps) => {
  const theme = useTheme();

  return (
    <List {...props} {...ListFunctionalities}>
      <Datagrid>
        <TextField style={{ fontWeight: "bold" }} source="code" />
        <BadgeField
          setStyle={(value) => {
            let color: string;
            switch (value) {
              case VoucherStatus.ACTIVE:
                color = theme.palette.primary.main;
                break;
              case VoucherStatus.ARCHIVED:
                color = theme.palette.warning.main;
                break;
              case VoucherStatus.AVAILABLE:
                color = theme.palette.secondary.main;
                break;
              default:
                color = theme.palette.error.main;
            }
            return { backgroundColor: color };
          }}
          source="status"
        />

        <ReferenceField source="groupId" reference="voucher-groups">
          <TextField source="name" />
        </ReferenceField>

        <TextField source="type" />
        <DateField source="activatedAt" />
        <DateField source="createdAt" />
        <NumberField source="validityDuration" />
        <EditButton />
        <DeleteButton />
        <ShowButton />
      </Datagrid>
    </List>
  );
};

export default VoucherList;
