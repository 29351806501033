import { fetchUtils } from "ra-core";
import { Servers } from ".";

const HttpClient = (
  url: string,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  options: any = {}
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
): Promise<{ status: number; headers: Headers; body: string; json: any }> => {
  const headers = options?.headers || new Headers({});

  const { url: serverUrl, token } = Servers.get("SERVER");

  if (token) headers.set("Authorization", `Bearer ${token}`);

  return fetchUtils.fetchJson(`${serverUrl}/${url}`, {
    ...options,
    headers: headers,
  });
};

export default HttpClient;