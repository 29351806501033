import { DataProvider as RaDataProvider } from "ra-core";
import { HttpClient } from ".";
import { getSequelizeWhere, makeSingle } from "../../Helpers";

const DataProvider = (httpClient = HttpClient): RaDataProvider => ({
  getList: (resource, params) => {
    const { field, order } = params.sort;

    const { page, perPage } = params.pagination;

    const offset = (page - 1) * perPage;

    const query = JSON.stringify({
      order: [[field, order]],
      limit: perPage,
      offset: offset,
      ...getSequelizeWhere(params.filter),
    });

    const url = `${resource}?q=${btoa(query)}`;
    const options = {};

    return httpClient(url, options).then(({ json }) => {
      return {
        data: [...json?.entities],
        total: json?.total,
      };
    });
  },

  getOne: (resource, params) =>
    httpClient(`${makeSingle(resource)}/${params.id}`).then(({ json }) => ({
      data: json,
    })),

  getMany: (resource, params) => {
    const query = JSON.stringify({
      where: { id: params.ids },
    });

    const url = `${resource}?q=${btoa(JSON.stringify(query))}`;
    return httpClient(url).then(({ json }) => ({ data: json.entities }));
  },

  getManyReference: (resource, params) => {
    const { page, perPage } = params.pagination;
    const { field, order } = params.sort;

    const rangeStart = (page - 1) * perPage;
    const rangeEnd = page * perPage - 1;

    const query = JSON.stringify({
      sort: JSON.stringify([field, order]),
      range: JSON.stringify([rangeStart, rangeEnd]),
      filter: JSON.stringify({
        ...getSequelizeWhere(params.filter),
        [params.target]: params.id,
      }),
    });

    const url = `${resource}?q=${btoa(query)}`;
    const options = {};

    return httpClient(url, options).then(({ json }) => {
      return {
        data: json.entities,
        total: json.total,
      };
    });
  },

  update: (resource, params) => {
    delete params.data["id"];
    delete params.data["createdAt"];
    delete params.data["updatedAt"];

    return httpClient(`${makeSingle(resource)}/${params.id}`, {
      method: "PUT",
      body: JSON.stringify(params.data),
    }).then(({ json }) => ({ data: json }));
  },

  updateMany: (resource, params) => {
    delete params.data["id"];
    delete params.data["createdAt"];
    delete params.data["updatedAt"];

    return Promise.all(
      params.ids.map((id) =>
        httpClient(`${makeSingle(resource)}/${id}`, {
          method: "PUT",
          body: JSON.stringify(params.data),
        })
      )
    ).then((responses) => ({ data: responses.map(({ json }) => json.id) }));
  },

  create: (resource, params) =>
    httpClient(`${makeSingle(resource)}`, {
      method: "POST",
      body: JSON.stringify(params.data),
    }).then(({ json }) => ({
      data: { ...params.data, id: json.id },
    })),

  delete: (resource, params) =>
    httpClient(`${makeSingle(resource)}/${params.id}`, {
      method: "DELETE",
      headers: new Headers({
        "Content-Type": "text/plain",
      }),
    }).then(({ json }) => ({ data: json })),

  // simple-rest doesn't handle filters on DELETE route, so we fallback to calling DELETE n times instead
  deleteMany: (resource, params) =>
    Promise.all(
      params.ids.map((id) =>
        httpClient(`${makeSingle(resource)}/${id}`, {
          method: "DELETE",
          headers: new Headers({
            "Content-Type": "text/plain",
          }),
        })
      )
    ).then((responses) => ({
      data: responses.map(({ json }) => json.id),
    })),
});

export default DataProvider;
