import React from "react";
import { DateInput, Filter, SelectInput } from "react-admin";
import { mapEnum } from "../../../Helpers";
import { SynchroHistoryStatus } from "../../../Types/Enums";

const ListFilters: React.FC = () => {
  return (
    <Filter>
      <SelectInput source="status" label={"Status"} choices={mapEnum(SynchroHistoryStatus)} alwaysOn />
      <DateInput source="updatedAt:lte" label={"Max validity"} alwaysOn />
      <DateInput source="updatedAt:gte" label={"Min validity"} alwaysOn />
      <DateInput source="createdAt:lte" label={"Max created"} alwaysOn />
      <DateInput source="createdAt:gte" label={"Min created"} alwaysOn />
    </Filter>
  );
}

export default ListFilters;