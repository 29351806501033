import { ResourceConfiguration } from "./Core";

import {
  VpnKey,
  AttachMoney,
  AirlineSeatReclineExtra,
  Storage,
  LocalOffer,
  Sync,
} from "@material-ui/icons";

import PaymentCredentials from "./Pages/PaymentCredentials";
import Payments from "./Pages/Payments";
import PaymentSessions from "./Pages/PaymentSessions";
import Vouchers from "./Pages/Vouchers";
import VouchersGroups from "./Pages/VouchersGroups";
import SynchroHistory from "./Pages/SynchroHistory";

const AppResources: ResourceConfiguration[] = [
  {
    enabled: true,
    name: "vouchers",
    icon: LocalOffer,
    ...Vouchers,
    children: [
      {
        enabled: true,
        name: "voucher-groups",
        icon: Storage,
        ...VouchersGroups,
      },
      {
        enabled: false,
        name: "synchro-history",
        icon: Sync,
        ...SynchroHistory,
      },
    ],
  },
  {
    enabled: true,
    name: "payments",
    icon: AttachMoney,
    ...Payments,
    children: [
      {
        enabled: true,
        name: "payment-credentials",
        icon: VpnKey,
        ...PaymentCredentials,
      },
      {
        enabled: true,
        name: "payment-sessions",
        icon: AirlineSeatReclineExtra,
        ...PaymentSessions,
      },
    ],
  },
];

export default AppResources;
