import React, { ComponentType, ReactElement, useCallback } from "react";

import {
  ListItemText,
  ListItemIcon,
  Collapse,
  List,
  ListItem,
} from "@material-ui/core";

import { ExpandLess, ExpandMore, ArrowRight } from "@material-ui/icons";

export interface MenuGroupProps {
  icon?: ComponentType<unknown>;
  name: string;
  children: ReactElement[] | ReactElement;
}

const MenuGroup: React.FC<MenuGroupProps> = (props: MenuGroupProps) => {
  const [open, setOpen] = React.useState<boolean>(false);

  const onOpen = useCallback(() => {
    setOpen(!open);
  }, [setOpen, open]);

  const Icon = props.icon ? props.icon : ArrowRight;

  return (
    <>
      <ListItem button onClick={onOpen} selected={open}>
        <ListItemIcon>
          <Icon color={"primary"} />
        </ListItemIcon>
        <ListItemText primary={props.name} />
        {open ? <ExpandLess /> : <ExpandMore />}
      </ListItem>
      <Collapse in={open} unmountOnExit>
        <List disablePadding>{props.children}</List>
      </Collapse>
    </>
  );
};

export default MenuGroup;