import React from "react";
import { Filter, ReferenceInput, SelectInput, TextInput } from "react-admin";
import { mapEnum } from "../../../Helpers";
import { VoucherStatus, VoucherType } from "../../../Types/Enums";

const ListFilters: React.FC = () => {
  return (
    <Filter>
      <TextInput source="code:substring" label={"Code"} alwaysOn />
      <ReferenceInput source="groupId" reference="voucher-groups" alwaysOn >
        <SelectInput source="name" alwaysOn />
      </ReferenceInput>
      <SelectInput source="type" label={"Type"} choices={mapEnum(VoucherType)} alwaysOn />
      <SelectInput source="status" label={"Status"} choices={mapEnum(VoucherStatus)} alwaysOn />
    </Filter>
  );
}

export default ListFilters;