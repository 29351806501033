import { defaultTheme } from "react-admin";

import { createTheme } from "@material-ui/core";

const theme = createTheme({
  ...defaultTheme,
  palette: {
    primary: { main: "#34AADC" },
    secondary: {
      main: "#5AC8FA",
      light: "#5AC8FA",
      dark: "#5AC8FA",
    },
    warning: { main: "#FF9500" },
    error: { main: "#FF2D55" },
    divider: "#000000",
  },
  spacing: 8,
  typography: {
    fontFamily: [
      "-apple-system",
      "BlinkMacSystemFont",
      "Arial",
      "sans-serif",
    ].join(","),
  },

  shadows: [
    "none",
    "0px 0px 12px 1px rgba(0,0,0,0.03)",
    "0px 0px 12px 1px rgba(0,0,0,0.03)",
    "0px 0px 12px 1px rgba(0,0,0,0.03)",
    "0px 0px 12px 1px rgba(0,0,0,0.03)",
    "0px 0px 12px 1px rgba(0,0,0,0.03)",
    "0px 0px 12px 1px rgba(0,0,0,0.03)",
    "0px 0px 12px 1px rgba(0,0,0,0.03)",
    "0px 0px 12px 1px rgba(0,0,0,0.03)",
    "0px 0px 12px 1px rgba(0,0,0,0.03)",
    "0px 0px 12px 1px rgba(0,0,0,0.03)",
    "0px 0px 12px 1px rgba(0,0,0,0.03)",
    "0px 0px 12px 1px rgba(0,0,0,0.03)",
    "0px 0px 12px 1px rgba(0,0,0,0.03)",
    "0px 0px 12px 1px rgba(0,0,0,0.03)",
    "0px 0px 12px 1px rgba(0,0,0,0.03)",
    "0px 0px 12px 1px rgba(0,0,0,0.03)",
    "0px 0px 12px 1px rgba(0,0,0,0.03)",
    "0px 0px 12px 1px rgba(0,0,0,0.03)",
    "0px 0px 12px 1px rgba(0,0,0,0.03)",
    "0px 0px 12px 1px rgba(0,0,0,0.03)",
    "0px 0px 12px 1px rgba(0,0,0,0.03)",
    "0px 0px 12px 1px rgba(0,0,0,0.03)",
    "0px 0px 12px 1px rgba(0,0,0,0.03)",
    "0px 0px 12px 1px rgba(0,0,0,0.03)",
  ],

  overrides: {
    MuiAppBar: {
      colorSecondary: { main: "#0056a2" },
    },
    MuiMenuItem: {
      root: {
        paddingTop: "15px",
        paddingBottom: "15px"
      }
    },
    MuiButton: {
      text: { fontWeight: "bold" },
      contained: { color: "white !important", fontWeight: "bold" },
    },
  },
});

export default theme;
