import { AuthProvider as RaAuthProvider } from "ra-core";
import { Servers } from ".";

const AuthProvider: RaAuthProvider = {
  login: async ({
    serverUrl,
    publicKey,
    contentApiUrl,
  }: {
    serverUrl: string;
    publicKey: string;
    contentApiUrl?: string;
  }) => {
    localStorage.clear();
    const request = new Request(`${serverUrl}/authenticate`, {
      method: "POST",
      body: JSON.stringify({ publicKey: publicKey }),
      headers: new Headers({ "Content-Type": "application/json" }),
    });

    const response = await fetch(request);
    if (response.status < 200 || response.status >= 300) {
      return Promise.reject(new Error(response.statusText));
    }

    const { token } = await response.json();

    Servers.set("CONTENT", contentApiUrl ?? "");
    Servers.set("SERVER", serverUrl, token);
  },
  checkError: (error) => {
    const status = error.status;
    if (status === 401 || status === 403) {
      localStorage.clear();
      return Promise.reject();
    }
    return Promise.resolve();
  },
  checkAuth: async () => {
    if (!Servers.exists("SERVER")) return Promise.reject();

    return Promise.resolve();
  },
  logout: async () => {
    localStorage.clear();
  },
  getPermissions: () => {
    return Promise.resolve();
  },
};

export default AuthProvider;
