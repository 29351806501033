import React from "react";

import ListActions from "./ListActions";
import ListBulkActions from "./ListBulkActions";
import ModalListFilters from "./ModalListFilters";
import ListFilters from "./ListFilters";

export default {
  actions: <ListActions />,
  bulkActionButtons: <ListBulkActions />,
  modalFilters: <ModalListFilters />,
  filters: <ListFilters />
};