import VoucherGroupList from "./List";
import VoucherGroupShow from "./Show";
import VoucherGroupCreate from "./Create";
import VoucherGroupEdit from "./Edit";

export default {
  list: VoucherGroupList,
  show: VoucherGroupShow,
  create: VoucherGroupCreate,
  edit: VoucherGroupEdit
}