import {
  ListProps,
  DateField,
  Datagrid,
  TextField,
  NumberField,
  ShowButton,
} from "react-admin";
import React from "react";

import ListFunctionalities from "./ListFunctionalities";
import { PaymentStatus } from "../../Types/Enums";
import { useTheme } from "@material-ui/core";
import { BadgeField, List } from "../../Components";

const PaymentList: React.FC<ListProps> = (props: ListProps) => {
  const theme = useTheme();
  return (
    <List {...props} {...ListFunctionalities}>
      <Datagrid rowClick="show">
        <BadgeField
          setStyle={(value) => {
            let color;
            switch (value) {
              case PaymentStatus.SUCCEDED:
                color = theme.palette.secondary.main;
                break;
              default:
                color = theme.palette.error.main;
            }
            return { backgroundColor: color };
          }}
          source="status"
        />
        <TextField style={{ fontWeight: "bold" }} source="buyerName" />
        <TextField style={{ fontWeight: "bold" }} source="buyerMail" />
        <NumberField source="amount" />
        <TextField source="currency" />
        <TextField source="method" />
        <TextField source="reference" />
        <DateField source="createdAt" />
        <ShowButton />
      </Datagrid>
    </List>
  );
};

export default PaymentList;
