import * as React from "react";
import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";
import { BulkActionBodyProps } from "../../../../Components";
import { VoucherStatus } from "../../../../Types/Enums";
import { mapEnum } from "../../../../Helpers";

const BulkUpdateStatus: React.FC<BulkActionBodyProps> = (
  props: BulkActionBodyProps
) => {
  const options = mapEnum(VoucherStatus);

  return (
    <Select
      name={"status"}
      value={props.getState("status", options[0].id)}
      onChange={props.setState}
    >
      {options.map((value) => (
        <MenuItem key={value.id} value={value.id}>
          {value.name}
        </MenuItem>
      ))}
    </Select>
  );
};

export default BulkUpdateStatus;
