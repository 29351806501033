import React from "react";
import { useListContext } from "react-admin";
import { TablePagination } from "@material-ui/core";
import { ListPaginationActions } from ".";

const ListPagination: React.FC = () => {

  const { page, perPage, total, setPage, setPerPage } = useListContext();

  const onPageChange = (event: React.MouseEvent<HTMLButtonElement> | null, newPage: number) => setPage(newPage + 1);
  const onRowsChange = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => setPerPage(parseInt(event.target.value));

  return (
    <TablePagination
      rowsPerPageOptions={[5, 10, 25, 50, 100]}
      count={total}
      rowsPerPage={perPage}
      page={page - 1}
      onPageChange={onPageChange}
      onRowsPerPageChange={onRowsChange}
      ActionsComponent={ListPaginationActions}
    />
  )
};

export default ListPagination;