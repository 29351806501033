import React from "react";
import { DateInput, Filter } from "react-admin";

const ModalListFilters: React.FC = () => (
  <Filter>
    <DateInput source="createdAt:lte" label={"Max created"} alwaysOn />
    <DateInput source="createdAt:gte" label={"Min created"} alwaysOn />
    <DateInput source="updatedAt:lte" label={"Max updated"} alwaysOn />
    <DateInput source="updatedAt:gte" label={"Min updated"} alwaysOn />
  </Filter>
);

export default ModalListFilters;