import React from "react";
import {
  ShowProps,
  Show,
  SimpleShowLayout,
  TextField,
  DateField,
  // ReferenceManyField,
  // Datagrid,
} from "react-admin";
// import SynchroHistoryStatus from "../../Types/Enums/SynchroHistoryStatus";
// import { useTheme } from "@material-ui/core";
// import BadgeField from "../../Components/Lists/BadgeField";
// import CustomList from "../../Components/Lists/CustomList";
// import ListFilters from "../SynchroHistory/ListFunctionalities/ListFilters";

const VoucherGroupShow: React.FC<ShowProps> = (props: ShowProps) => {
  // const theme = useTheme();
  return (
    <Show {...props}>
      <SimpleShowLayout>
        <TextField source="id" />
        <TextField source="name" />
        <DateField source="createdAt" />
        <DateField source="updatedAt" />
        {/* <div style={{ display: "flex", flexDirection: "column" }}>
          <ReferenceManyField reference="synchro-history" target="voucherGroupId" label="Synchro History">
            <CustomList filters={<ListFilters />}>
              <Datagrid>
                <TextField source="id" />
                <BadgeField
                  setStyle={(value) => {
                    let color;
                    switch (value) {
                      case SynchroHistoryStatus.SUCCEDED:
                        color = theme.palette.success.main;
                        break;
                      case SynchroHistoryStatus.NO_CHANGES:
                        color = theme.palette.primary.main;
                        break;
                      default:
                        color = theme.palette.error.main;
                    }
                    return { backgroundColor: color };
                  }}
                  source="status"
                />
                <DateField source="createdAt" />
                <DateField source="updatedAt" />
              </Datagrid>
            </CustomList>
          </ReferenceManyField>
        </div> */}
      </SimpleShowLayout>
    </Show>
  );
};

export default VoucherGroupShow;
