import React from "react";
import { useSelector } from "react-redux";
import {
  getResources,
  MenuItemLink,
  MenuProps,
  ResourceProps,
  Sidebar,
  ResourceDefinition,
} from "react-admin";

import { Card, CardContent } from "@material-ui/core";

import { ArrowRight, Folder } from "@material-ui/icons";
import { MenuGroup } from ".";

interface ResourceOptions {
  enabled: boolean;
}

const isEnabled = (props: ResourceProps) =>
  (props.options as ResourceOptions)?.enabled;

const Menu: React.FC<MenuProps> = () => {
  const grouped: Map<string, ResourceProps[]> = useSelector<
    unknown,
    ResourceDefinition[]
  >(getResources).reduce((acc, cur) => {
    if (!cur.hasList) return acc;
    return acc.set(cur.options.parent, [
      ...(acc.get(cur.options.parent) || []),
      cur,
    ]);
  }, new Map());

  return (
    <div>
      {grouped && (
        <Card style={{ maxWidth: "240px", marginLeft: "25px" }}>
          <Sidebar classes={{}}>
            <>
              {Array.from(grouped).map(([group, resources], index) => (
                <CardContent style={{ padding: 0 }} key={index}>
                  {group === undefined ? (
                    <div key={index}>
                      {resources.filter(isEnabled).map((resource) => {
                        const Icon = resource.icon ?? ArrowRight;
                        return (
                          <MenuItemLink
                            key={resource.name}
                            to={`/${resource.name}`}
                            primaryText={resource.name}
                            leftIcon={<Icon color="primary" />}
                          />
                        );
                      })}
                    </div>
                  ) : (
                    <MenuGroup
                      name={group}
                      key={group}
                      icon={
                        resources.find((m) => m.name === group)?.icon ?? Folder
                      }
                    >
                      {resources.filter(isEnabled).map((resource) => {
                        const Icon = resource.icon ?? ArrowRight;
                        return (
                          <MenuItemLink
                            key={resource.name}
                            to={`/${resource.name}`}
                            primaryText={resource.name}
                            leftIcon={<Icon color="action" />}
                          />
                        );
                      })}
                    </MenuGroup>
                  )}
                </CardContent>
              ))}
            </>
          </Sidebar>
        </Card>
      )}
    </div>
  );
};

export default Menu;
