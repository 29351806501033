import { applyMiddleware, compose } from "redux";
import { routerMiddleware, connectRouter } from "connected-react-router";
import createSagaMiddleware from "redux-saga";
import { all, fork } from "redux-saga/effects";
import { adminReducer, adminSaga, AuthProvider, DataProvider } from "react-admin";
import { init, Models, RematchStore } from "@rematch/core";
import { models } from "@interactive-mobility/flymingo-react-client";
import { RootModel } from "@interactive-mobility/flymingo-react-client/lib/model";
import { History } from 'history';

interface AppStoreProps{
  authProvider: AuthProvider;
  dataProvider: DataProvider;
  history: History;
}

export default (props: AppStoreProps): RematchStore<RootModel, Models<RootModel>> => {
  const sagaMiddleware = createSagaMiddleware();

  const store = init({
    models: models,
    redux: {
      initialState: {},
      reducers: {
        admin: adminReducer,
        router: connectRouter(props.history),
      },
      enhancers: [
        compose(applyMiddleware(sagaMiddleware, routerMiddleware(props.history))),
      ],
    },
  });

  const saga = function* rootSaga() {
    yield all([adminSaga(props.dataProvider, props.authProvider)].map(fork));
  };
  sagaMiddleware.run(saga);

  return store;
};
