import {
  DateField,
  Show,
  ShowProps,
  SimpleShowLayout, TextField,
} from "react-admin";
import React from "react";

const PaymentCredentialShow: React.FC<ShowProps> = (props: ShowProps) => {
  return (
    <Show {...props}>
      <SimpleShowLayout>
        <TextField source="id" />
        <TextField source="provider" />
        <TextField source="credentialsB64" />
        <DateField source="createdAt" />
        <DateField source="updatedAt" />
      </SimpleShowLayout>
    </Show>
  );
};

export default PaymentCredentialShow;