import React from "react";
import { Filter, SelectInput, TextInput } from "react-admin";
import { mapEnum } from "../../../Helpers";
import { PaymentSessionStatus } from "../../../Types/Enums";

const ListFilters: React.FC = () => {
  return (
    <Filter>
      <TextInput source="device:substring" label={"Device"} alwaysOn />
      <SelectInput
        source="status"
        label={"Status"}
        choices={mapEnum(PaymentSessionStatus)}
        alwaysOn
      />
    </Filter>
  );
};

export default ListFilters;
