import VoucherList from "./List";
import VoucherCreate from "./Create";
import VoucherEdit from "./Edit";
import VoucherShow from "./Show";

export default {
  list: VoucherList,
  create: VoucherCreate,
  edit: VoucherEdit,
  show: VoucherShow
}