import {
  DateField, ReferenceField,
  Show,
  ShowProps,
  SimpleShowLayout,
  TextField,
} from "react-admin";
import React from "react";

const PaymentSessionShow: React.FC<ShowProps> = (props: ShowProps) => {
  return (
    <Show {...props} title={"Payment session"}>
      <SimpleShowLayout>

        <TextField source="id" />

        <ReferenceField reference={"voucher-groups"} source={"deviceId"}>
          <TextField source={"name"} />
        </ReferenceField>
        <TextField source="status" />

        <TextField source="currency" />
        <TextField source="totalAmount" />
        <TextField source="amountToPay" />

        <TextField source="purchaseSessionId" />

        <DateField source="updatedAt" />
        <DateField source="createdAt" />

      </SimpleShowLayout>
    </Show>
  );
};

export default PaymentSessionShow;