import {
  ListProps,
  DateField,
  Datagrid,
  TextField,
  EditButton,
  DeleteButton,
} from "react-admin";
import { useTheme } from "@material-ui/core";
import React from "react";

import { BadgeField, ReferenceOneField, List } from "../../Components";
import ListFunctionalities from "./ListFunctionalities";
import { SynchroHistoryStatus } from "../../Types/Enums";

const VoucherGroupList: React.FC<ListProps> = (props: ListProps) => {
  const theme = useTheme();
  return (
    <List {...props} {...ListFunctionalities}>
      <Datagrid rowClick="show">
        <TextField style={{ fontWeight: "bold" }} source="name" />
        <DateField source="createdAt" />
        <DateField source="updatedAt" />
        <ReferenceOneField label="Last Synchro" reference="synchro-history">
          <BadgeField
            source="status"
            setStyle={(value) => {
              let color;
              switch (value) {
                case SynchroHistoryStatus.SUCCEDED:
                  color = theme.palette.success.main;
                  break;
                case SynchroHistoryStatus.NO_CHANGES:
                  color = theme.palette.primary.main;
                  break;
                default:
                  color = theme.palette.error.main;
              }
              return { backgroundColor: color };
            }}
          />
        </ReferenceOneField>
        <EditButton />
        <DeleteButton />
      </Datagrid>
    </List>
  );
};

export default VoucherGroupList;
