import React from "react";

import { AppBar, AppBarProps } from "react-admin";
import { Typography, makeStyles } from "@material-ui/core";

import Logo from "../../assets/moment.png";
import { Servers } from "../../Core";

const useStyles = makeStyles(({
  header: {
    background: "white"
  },
  title: {
    flex: 1,
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    overflow: 'hidden'
  },
  spacer: {
    flex: 1,
  },
  logo: {
    maxWidth: 150,
    marginRight: '10px'
  }
}));

const Header: React.FC<AppBarProps> = (props: AppBarProps) => {
  const classes = useStyles();

  const { url } = Servers.get("SERVER");

  return (
    <AppBar {...props} className={classes.header} >
      <Typography
        variant="h6"
        className={classes.title}
      >
        {url}
      </Typography>
      <img src={Logo} className={classes.logo} alt={"Logo"} />
      <span className={classes.spacer} />
    </AppBar>
  );
};

export default Header;