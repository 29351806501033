import React, { ReactElement, useCallback, useState } from "react";
import {
  Button,
  useUpdateMany,
  useRefresh,
  useUnselectAll,
  BulkActionProps,
  useNotify,
} from "react-admin";
import { Done, Close } from "@material-ui/icons";
import {
  FormControl,
  Dialog,
  DialogContent,
  DialogTitle,
  DialogActions,
} from "@material-ui/core";

interface BulkActionWrapperProps extends BulkActionProps {
  resource: string;
  onSuccess?: string;
  onFailure?: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  component: React.FC<any>;
  icon: ReactElement;
  label: string;
}

const BulkActionWrapper: React.FC<BulkActionWrapperProps> = (
  props: BulkActionWrapperProps
) => {
  const [state, _setState] = useState<{ [key: string]: string }>({});

  const setState = (event: React.ChangeEvent<HTMLInputElement>) => {
    _setState({ ...state, [event.target.name]: event.target.value });
  };

  const getState = (key: string, def: string) =>
    key in state ? state[key] : def;

  const refresh = useRefresh();
  const unselectAll = useUnselectAll();
  const notify = useNotify();

  const [updateMany, { loading }] = useUpdateMany(
    props.resource,
    props.selectedIds,
    state,
    {
      onSuccess: () => {
        refresh();
        notify(props.onSuccess ?? `Success`);
        unselectAll(props.resource);
        handleClose();
      },
      onFailure: (error) =>
        notify(props.onFailure ?? `Error: ${error}`, "warning"),
    }
  );

  const [open, setOpen] = React.useState(false);

  const handleOpen = useCallback(() => {
    setOpen(true);
  }, []);
  const handleClose = useCallback(() => {
    setOpen(false);
  }, []);

  return (
    <>
      <Button
        icon={props.icon}
        onClick={handleOpen}
        label={props.label}
        variant={"contained"}
      />
      <Dialog
        disableBackdropClick
        disableEscapeKeyDown
        open={open}
        onClose={handleClose}
      >
        <DialogTitle>{props.label}</DialogTitle>
        <DialogContent>
          <FormControl>
            <props.component
              {...props}
              getState={getState}
              setState={setState}
            />
          </FormControl>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} label={"Cancel"}>
            <Close />
          </Button>
          <Button onClick={updateMany} disabled={loading} label={"Ok"}>
            <Done />
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default BulkActionWrapper;
