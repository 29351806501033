import React, { ReactElement } from "react";

import { Admin } from "react-admin";
import AppResources from "./AppResources";
import { DataProvider, AuthProvider, Resources } from "./Core";
import { Login, Layout } from "./Components";
import theme from "./Theme";
import { ThemeProvider, CssBaseline } from "@material-ui/core";
import { createHashHistory } from "history";
import models from "@interactive-mobility/flymingo-react-client";
import { Provider } from "react-redux";
import AppStore from "./AppStore";

const App = (): ReactElement => {
  const dataProvider = DataProvider();
  const history = createHashHistory();
  const authProvider = AuthProvider;

  return (
    <Provider
      store={AppStore({
        dataProvider,
        history,
        authProvider,
      })}
    >
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <Admin
          dataProvider={dataProvider}
          authProvider={authProvider}
          history={history}
          customReducers={models}
          layout={Layout}
          loginPage={Login}
          theme={theme}
        >
          {Resources(AppResources)}
        </Admin>
      </ThemeProvider>
    </Provider>
  );
};

export default App;
