import { FlymingoClient } from "@interactive-mobility/flymingo-web-client/lib/model";

type ServerType = "SERVER" | "CONTENT";

const Servers: {
  exists: (type: ServerType) => boolean;
  get: (type: ServerType) => { url?: string; token?: string };
  set: (type: ServerType, url: string, token?: string) => void;
} = {
  exists: (type: ServerType) => !!localStorage.getItem(type),
  get: (type: ServerType) => {
    if (!Servers.exists(type)) return {};

    return { type, ...JSON.parse(localStorage.getItem(type) ?? "{}") };
  },
  set: (type: ServerType, url?: string, token?: string) => {
    const server = JSON.stringify({ url, type, token });
    localStorage.setItem(type, server);
  },
};

const DefaultFlymingoClient = (): FlymingoClient | undefined => {
  const { url } = Servers.get("CONTENT");

  if (!url) return undefined;

  return new FlymingoClient(url);
};

export type { ServerType };
export { DefaultFlymingoClient, Servers };
