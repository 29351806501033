import {
  Create,
  CreateProps,
  NumberInput,
  ReferenceInput,
  SelectInput,
} from "react-admin";
import React, { useState } from "react";

import { VoucherType } from "../../Types/Enums";
import { mapEnum } from "../../Helpers";
import { Form } from "../../Components";
import { DefaultFlymingoClient } from "../../Core";
import { hooks } from "@interactive-mobility/flymingo-react-client";
import { defaultSelector } from "@interactive-mobility/flymingo-react-client/lib/model";

const VoucherCreate: React.FC<CreateProps> = (props: CreateProps) => {
  const [getVoucherType, setVoucherType] = useState<VoucherType>(
    VoucherType.PRODUCT
  );

  hooks.Shop.useShopProducts({ options: { client: DefaultFlymingoClient() } });
  const products = Object.values(
    defaultSelector((state) => state.flymingo_shop.products.map)
  ).map((product) => {
    return { id: product["uid"], name: product.title };
  });

  const onVoucherTypeChanged = (event: React.ChangeEvent<HTMLInputElement>) =>
    setVoucherType(VoucherType[event.target.value as VoucherType]);

  return (
    <Create {...props} resource={"voucher-bulk"}>
      <Form redirect={props.basePath} omitted={["num"]}>
        <NumberInput source={"num"} label={"Quantity"} />
        <ReferenceInput reference={"voucher-groups"} source={"groupId"}>
          <SelectInput optionText="name" />
        </ReferenceInput>
        <NumberInput source={"duration"} />
        <SelectInput
          source={"type"}
          defaultValue={getVoucherType}
          choices={mapEnum(VoucherType)}
          onChange={onVoucherTypeChanged}
        />
        {getVoucherType === VoucherType.PRODUCT ? (
          <SelectInput source={"product"} choices={products} />
        ) : (
          <NumberInput source={"amount"} />
        )}
      </Form>
    </Create>
  );
};

export default VoucherCreate;
