import {
  DateField,
  Show,
  ReferenceField,
  ShowProps,
  SimpleShowLayout,
  TextField, NumberField,
} from "react-admin";
import React from "react";

const VoucherShow: React.FC<ShowProps> = (props: ShowProps) => {
  return (
    <Show {...props} title={"Voucher details"}>
      <SimpleShowLayout>
        <TextField source="id" />
        <TextField source="product" />

        <ReferenceField label={"Voucher group"} reference={"voucher-groups"} source={"groupId"}>
          <TextField source="name" />
        </ReferenceField>

        <TextField source="code" />
        <TextField source="type" />

        <NumberField source="validityDuration" />

        <NumberField source="amount" />

        <TextField source="status" />
        <DateField source="activatedAt" />

        <DateField source="updatedAt" />
        <DateField source="createdAt" />

      </SimpleShowLayout>
    </Show>
  );
};

export default VoucherShow;