import React from "react";
import { useRecordContext, PublicFieldProps } from "react-admin";

export interface StylizedFieldProps extends PublicFieldProps {
  setStyle: (value: string) => React.CSSProperties;
  setValue?: (value: string) => string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  component?: React.FC<any>;
}

const StylizedField: React.FC<StylizedFieldProps> = (
  props: StylizedFieldProps
) => {
  const record = useRecordContext();

  const value = record[props.source ?? ""];

  if (props.component)
    return (
      <props.component style={props.setStyle(value)}>
        {record && value}
      </props.component>
    );

  return <span style={props.setStyle(value)}>{record && value}</span>;
};

export default StylizedField;
