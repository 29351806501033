import {
  Create,
  CreateProps,
  TextInput
} from "react-admin";
import React from "react";
import { Form } from "../../Components";

const VoucherGroupCreate: React.FC<CreateProps> = (props: CreateProps) => {
  return (
    <Create {...props}>
      <Form>
        <TextInput source={"name"} />
      </Form>
    </Create>
  );
}

export default VoucherGroupCreate;