import React from "react";
import {
  ShowProps,
  TextField,
  DateField,
  Datagrid,
  ReferenceField,
} from "react-admin";
import { SynchroHistoryStatus } from "../../Types/Enums";
import { useTheme } from "@material-ui/core";
import { BadgeField, List } from "../../Components";
import ListFunctionalities from "./ListFunctionalities";

const SynchroHistoryList: React.FC<ShowProps> = (props: ShowProps) => {
  const theme = useTheme();
  return (
    <List {...props} {...ListFunctionalities}>
      <Datagrid>
        <BadgeField
          setStyle={(value) => {
            let color: string;
            switch (value) {
              case SynchroHistoryStatus.SUCCEDED:
                color = theme.palette.success.main;
                break;
              case SynchroHistoryStatus.NO_CHANGES:
                color = theme.palette.primary.main;
                break;
              default:
                color = theme.palette.error.main;
            }
            return { backgroundColor: color };
          }}
          source="status"
        />
        <DateField source="createdAt" />
        <DateField source="updatedAt" />
        <ReferenceField reference="voucher-groups" source="voucherGroupId">
          <TextField source="name" />
        </ReferenceField>
      </Datagrid>
    </List>
  );
};

export default SynchroHistoryList;
