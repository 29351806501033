import React from "react";
import { DateInput, Filter, TextInput } from "react-admin";

const ModalListFilters: React.FC = () => {
  return (
    <Filter>
      <TextInput source="method:substring" label={"Method"} alwaysOn />
      <TextInput source="reference:substring" label={"Reference"} alwaysOn />
      <TextInput source="currency:substring" label={"Currency"} alwaysOn />
      <DateInput source="updatedAt:lte" label={"Max updated"} alwaysOn />
      <DateInput source="updatedAt:gte" label={"Min updated"} alwaysOn />
      <DateInput source="createdAt:lte" label={"Max created"} alwaysOn />
      <DateInput source="createdAt:gte" label={"Min created"} alwaysOn />
    </Filter>
  );
}

export default ModalListFilters;